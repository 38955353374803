import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { AuthenticationContext } from "./AuthenticationContext";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
} from "@apollo/client";
import { withApollo } from '@apollo/client/react/hoc';
import { CSComponent } from 'react-central-state';
import { GET_CUSTOMER } from "./views/garage-app/Queries/JobOrderQueries";



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));

class App extends Component {
  initData = () => {
    this.props.client.query({ query: GET_CUSTOMER })
      .then(result => {
        this.setCentralState({
          customers: result.data.customers || [],
          dataReady: true
        });
      })
      .catch(() => { });
  }

  componentDidMount() {
    this.setCentralState({
      dataReady: false,
      authUser: {},
      jobCards: [],
      customerAsset: []
    });

    this.initData();

  }
  render() {
    // console.log('in app')
    return (
      <HashRouter>
        {/* <ApolloProvider client={client}> */}
          {/* <AuthenticationContext.Provider> */}
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
          {/* </AuthenticationContext.Provider> */}
        {/* </ApolloProvider> */}
      </HashRouter>
    );
  }
  updateWith() { return ['dataReady']; }
}

export default withApollo(CSComponent(App));
