import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query {
    customers {
      id
      companyName
      address
      contacts {
        id
        fullName
        phone
        email
      }
      customerAssets {
        id
        assetNumber
        makeModel
        year
        odometer
      }
    }
  }
`;

export const GET_CONTACTS = gql`
  query {
    contacts {
      id
      customerId
      fullName
      phone
      email
    }
  }
`;

export const GET_ASSETS = gql`
  query {
    customerAssets {
      id
      assetNumber
      makeModel
      year
      odometer
      customer {
        id
        companyName
      }
    }
  }
`;

export const GET_MODEL = gql`
  query {
    getUsualWordBrandNames {
      word
    }
  }
`;

export const GET_PRODUCTS = gql`
  query {
    products {
      id
      productCode
      productName
      costPrice
      sellingPrice
      minSellingPrice
      availableStock
      skills {
        id
        skillName
        jobTime
      }
    }
  }
`;

export const GET_SKILLS = gql`
  query {
    skills {
      id
      skillName
    }
  }
`;

export const GET_JOBCARD_BY_DATE_RANGE = gql`
  query($startTime: String!, $endTime: String!) {
    getJobCardByDateRange(startTime: $startTime, endTime: $endTime) {
      id
      assetId
      contactId
      customerId
      updateTime
      status
    }
  }
`;
export const GET_JOBCARD_BY_ID = gql`
  query($jobCardId: ID!) {
    getJobCardById(jobCardId: $jobCardId) {
      id
      assetId
      contactId
      customerId
      updateTime
      status
      odometer
      note
      referenceNumber
      totalPrice
      discountPrice
      productItems {
        id
        productId
        productCode
        productName
        costPrice
        sellingPrice
        quantity
      }
      updates {
        jobCardId
        updateText
        status
        updateTime
        updateUserId
      }
    }
  }
`;

// mutation
export const ADD_CUSTOMER = gql`
  mutation CreateCustomer(
    $name: String!
    $address: String!
    $city: String!
    $province: String!
    $country: String!
  ) {
    createCustomer(
      params: {
        companyName: $name
        address: $address
        city: $city
        province: $province
        country: $country
      }
    ) {
      id
      companyName
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation CreateContact(
    $customerId: ID!
    $fullName: String!
    $phone: String!
    $email: String!
    $defaultContact: Boolean!
  ) {
    createContact(
      customerId: $customerId
      params: {
        fullName: $fullName
        phone: $phone
        email: $email
        defaultContact: $defaultContact
      }
    ) {
      id
      customerId
      fullName
      phone
      email
      defaultContact
    }
  }
`;

export const ADD_ASSETS = gql`
  mutation CreateAsset(
    $customerId: ID!
    $assetNumber: String!
    $makeModel: String!
    $year: String!
    $odometer: String!
  ) {
    createCustomerAsset(
      customerId: $customerId
      params: {
        assetNumber: $assetNumber
        makeModel: $makeModel
        year: $year
        odometer: $odometer
      }
    ) {
      id
      assetNumber
      makeModel
      year
      odometer
    }
  }
`;

export const ADD_MODEL = gql`
  mutation CreateModel($modelName: String!) {
    saveUsualWordBrandName(word: $modelName) {
      word
    }
  }
`;

export const ADD_SKILLS = gql`
  mutation CreateSkill($skill: String!) {
    createSkill(skillName: $skill) {
      id
      skillName
    }
  }
`;

export const ADD_PRODUCT_JOB = gql`
  mutation CreateProductJob(
    $productCode: String!
    $productName: String!
    $costPrice: Float!
    $sellingPrice: Float!
    $minSellingPrice: Float!
    $skills: [SkillJobInput!]
  ) {
    createProductJob(
      params: {
        productCode: $productCode
        productName: $productName
        costPrice: $costPrice
        sellingPrice: $sellingPrice
        minSellingPrice: $minSellingPrice
      }
      skills: $skills
    ) {
      id
      productCode
      productName
      costPrice
      sellingPrice
      minSellingPrice
      availableStock
    }
  }
`;

export const ADD_JOBCARD = gql`
  mutation CreateJobCard(
    $referenceNumber: String!
    $jobCardDate: String!
    $odometer: String!
    $note: String!
    $totalPrice: Float!
    $discountPrice: Float!
    $assetId: Int!
    $customerId: Int!
    $contactId: Int!
    $status: String!
    $products: [JobCardProductInput!]
  ) {
    createJobCard(
      params: {
        referenceNumber: $referenceNumber
        jobCardDate: $jobCardDate
        odometer: $odometer
        note: $note
        totalPrice: $totalPrice
        discountPrice: $discountPrice
        assetId: $assetId
        customerId: $customerId
        contactId: $contactId
        status: $status
      }
      products: $products
    ) {
      id
      referenceNumber
      jobCardDate
    }
  }
`;
export const ADD_JOBCARD_UPDATE = gql`
  mutation AddJobCardUpdate($id: ID!, $updateText: String!, $status: String!) {
    addJobCardUpdate(jobCardId: $id, updateText: $updateText, status: $status) {
      updateText
      status
      updateUserId
      updateTime
    }
  }
`;

//Updates
export const UPDATE_JOBCARD = gql`
  mutation UpdateJobCard(
    $id: ID!
    $referenceNumber: String!
    $jobCardDate: String!
    $odometer: String!
    $note: String!
    $totalPrice: Float!
    $discountPrice: Float!
    $assetId: Int!
    $customerId: Int!
    $contactId: Int!
    $status: String!
  ) {
    updateJobCard(
      id: $id
      params: {
        referenceNumber: $referenceNumber
        jobCardDate: $jobCardDate
        odometer: $odometer
        note: $note
        totalPrice: $totalPrice
        discountPrice: $discountPrice
        assetId: $assetId
        customerId: $customerId
        contactId: $contactId
        status: $status
      }
    ) {
      id
      referenceNumber
      jobCardDate
    }
  }
`;

export const UPDATE_PRODUCT_JOB = gql`
  mutation UpdateProduct(
    $productId: Int!
    $productCode: String!
    $productName: String!
    $costPrice: Float!
    $sellingPrice: Float!
    $minSellingPrice: Float!
    $productStatus: String!
  ) {
    updateProduct(
      productId: $productId
      params: {
        productCode: $productCode
        productName: $productName
        costPrice: $costPrice
        sellingPrice: $sellingPrice
        minSellingPrice: $minSellingPrice
      }
      productStatus: $productStatus
    ) {
      id
      productCode
      productName
      costPrice
      sellingPrice
      minSellingPrice
      productStatus
      updateTime
    }
  }
`;
export const UPDATE_SKILL = gql`
  mutation UpdateSkill($id: Int!, $skillName: String!) {
    updateSkill(id: $id, skillName: $skillName) {
      id
      skillName
    }
  }
`;

//DELETE PRODUCT-DELETE JOBCARD
export const REMOVE_PRODUCT_JOB = gql`
  mutation RemoveJobCardProduct($id: ID!, $itemId: ID!) {
    removeJobCardProduct(jobCardId: $id, itemId: $itemId)
  }
`;
//ADD PRODUCT-UPDATE JOBCARD
export const ADD_PRODUCT_JOBCARD_UPDATE = gql`
  mutation AddJobCardProduct(
    $jobCardId: ID!
    $productId: ID!
    $productCode: String!
    $productName: String!
    $costPrice: Float!
    $sellingPrice: Float!
    $quantity: Float!
  ) {
    addJobCardProduct(
      jobCardId: $jobCardId
      product: {
        productId: $productId
        productCode: $productCode
        productName: $productName
        costPrice: $costPrice
        sellingPrice: $sellingPrice
        quantity: $quantity
      }
    ) {
      id
      productId
      productCode
      productName
      costPrice
      sellingPrice
      quantity
    }
  }
`;
